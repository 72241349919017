@-ms-viewport{

width:device-width;

}

@import '_font-awesome.min.scss';
@import 'bootstrap/_bootstrap.scss';
@import '_button-scroll.scss';

@import 'settings/mixins.scss';
@import 'settings/animations.scss';

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0px;
    font-family: arial;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    outline: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: white;
}

body{
    -webkit-transition: all 0.3s; /* Safari */
    transition: all 0.3s;
    overflow-x: hidden;
}

body.loaded-done .loader{
    display: none;
}

body.loaded-done .wrap-page,
body.loaded-done .main-bg{
    opacity: 1;
}

body.loaded-done .wrap-page{
    visibility: visible;
}

body.loaded-done .main-bg:after{
    height: 100%;
}

.wrap-page{
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.loader{
    &:after{
        content: "";
        background: url("../images/spinner.gif");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        z-index: 9;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);

        -webkit-transition: all 0.3s;
        transition: all 0.3s;

        @media (max-width: $screen-xs-max){
            width: 40px;
            height: 40px;
        }
    }
}    

a{
    text-decoration: none;
}

::-moz-selection { /* Code for Firefox */
    background: #00a0f9;
    color: white;
}

::selection {
    color: white;
    background: #00a0f9;
}

.social-media{
    display: inline-block;
    margin: 0;
    float: right;
    list-style-type: none;
}

.social-media li{
    display: inline-block;
    margin-left: 10px; 
}

.social-media li:first-of-type{
    margin-left: 0;
}

.social-media li a{
    color: white;
    font-size: 25px;
    text-decoration: none;
    -webkit-transition: all 0.2s; /* Safari */
    transition: all 0.2s;
}

.social-media li a:hover{
    color: #00a0f9;
}

.main-bg{
    opacity: 0;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: url('../images/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;

    @media (max-width: $screen-xs-max){
        border-right: 0;
    }

    &:before{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background: url('../images/pattern.png') repeat;
        z-index: 1;
        opacity: 1;
    }

    &:after{
        content: "";
        display: block;
        right: 0;
        top: 0;
        width: 5px;
        height: 0%;
        position: absolute;
        z-index: 2;
        background-color: #00a0f9;

        -webkit-transition: all 1.5s;
        transition: all 1.5s;
    }

}

.viber{
    display: block;
    width: 44px;
    height: 46px;
    background-image: url('../images/viber.webp');
    background-repeat: no-repeat;
    background-size: 100%;
    position: fixed;
    z-index: 2;
    bottom: 40px;
    right: 50px;
    @include global-transition(all, 0.3s);

    @media (max-width: $screen-xs-max){
        bottom: 15px;
        right: 20px;
    }

    &:hover {
        @include scale(1.1);
    }
}

section.main-banner-section{
    min-height: 100vh;
    overflow: hidden;
    width: 100vw;
    position: relative;

    .wrap-main-banner-inner-page{
        display: table;
        height: 100%;
        width: 100%;
    }

    .wrap-main-banner-inner-page .inner-page{
        display: table-cell;
        height: 100%;
        width: 100%;
        vertical-align: middle;
    }

    .container{
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 15px 15px 100px 15px;
    }

    h1{
        text-shadow: 1px 0px 6px rgb(81, 81, 81);
        font-size: 28px;
        line-height: 38px;
        margin: 0;
        font-weight: 300;

        @media (max-width: $screen-xs-max){
            font-size: 24px;
            line-height: 28px;
        }
    }

    .resume{
        position: relative;
        display: block;
        width: 230px;
        color: white;
        font-size: 22px;
        letter-spacing: 1px;
        background-color: #00a0f9;
        padding: 12px;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        margin: 30px auto 10px auto;
        -webkit-transition: all 0.2s; /* Safari */
        transition: all 0.2s;

        @media (max-width: $screen-xs-max){
            font-size: 20px;
            padding: 10px;
        }

        &:hover{
            opacity: 0.9;
        }

        .fa{
            margin-right: 10px;
        }
    }

    .profile-image{
        position: relative;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin: 20px auto;

        @media (max-width: $screen-xs-max){
            width: 100px;
            height: 100px;
        }

        &:after{
            content: "";
            position: absolute;
            border-radius: 50%;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            z-index: 0;
            border: 5px solid #00a0f9;
            -webkit-transition: all 0.2s; /* Safari */
            transition: all 0.2s;

            @media (max-width: $screen-xs-max){
                border: 2px solid #00a0f9;
            }
        }

        &:hover:after{
            background-color: rgba(0,160,249, 0.1);
            border: 7px solid #00a0f9;

            @media (max-width: $screen-xs-max){
                border: 4px solid #00a0f9;
            }
        }

        > img{
            max-width: 100%;
        }

    }

    .email{
        color: white;
        text-decoration: none;
        border-bottom: 1px solid white;
        font-weight: 400;
        text-shadow: 1px 0px 6px rgb(81, 81, 81);
    }

}

section.portfolio-section{
    background-color: rgba(255,255,255,0.8);

    .row{
        padding-top: 50px;    
        padding-bottom: 100px;      

        @media (max-width: $screen-xs-max){
            padding-top: 40px;    
            padding-bottom: 60px;     
        }
    }

    h3{
        margin-top: 50px;
        font-weight: 300;
        font-size: 22px;
        color: #00a0f9;

        @media (max-width: $screen-xs-max){
            margin-top: 25px;
            font-size: 20px;
            margin-bottom: 0px;
        }
    }

    h2{
        color: #3e3e3e;
        font-weight: 300;
        position: relative;
        display: inline-block;
        padding-bottom: 15px;
        margin-top: 0;

        &:after{
            content: "";
            width: 100px;
            height: 2px;
            background-color: #3e3e3e;
            display: block; 
            position: absolute;
            left: 50%;
            margin-left: -50px;
            bottom: 0px;
        }
    }

    .wrap-items{
        overflow: hidden;
        width: 100%;
        @include flex;
        @include flex-wrap;

        .item{
            margin-bottom: 15px;
            margin-top: 15px;

            .inner{
                position: relative;
                display: block;
                overflow: hidden;
                border: 1px solid #3e3e3e;

                &:hover{

                    .image{
                        img{
                            -webkit-transform: scale(1.1); 
                            -ms-transform: scale(1.1); 
                            -moz-transform: scale(1.1);
                            transform: scale(1.1);   
                        }
                    }

                    .desc{
                        opacity: 1;
                        visibility: visible;
                        z-index: 1;
                    }
                }

                .image{
                    img{
                        width: 100%;
                        -webkit-transition: all 0.2s; /* Safari */
                        transition: all 0.2s;
                    }
                }

                .desc{
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-color: rgba(0,160,249,0.8);
                    padding: 20px 30px;
                    opacity: 0;
                    visibility: hidden;
                    z-index: -5;

                    -webkit-transition: all 0.2s; /* Safari */
                    transition: all 0.2s;

                    .title{
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        display: block;
                    }

                    .skills{
                        font-size: 15px;
                        text-transform: lowercase;
                    }

                }

            }

        }
    }

}


footer{
    background-color: #3e3e3e;

    .row{
        padding: 25px 0;

        .col-sm-6{
            @media (max-width: $screen-xs-max){
                overflow: hidden;
                text-align: center;
            }
        }

        .social-media{
            @media (max-width: $screen-xs-max){
                float: none;
                margin-bottom: 5px;
            }
        }

        p{
            margin-top: 5px;
            margin-bottom: 0;
            display: inline-block;
            font-size: 12px;

            @media (max-width: $screen-xs-max){
                text-align: center;
            }

        }
    }
}